import { useMemo, ReactNode } from 'react';
import clsx from 'clsx';

import { Button } from 'components/Button';

type Props = {
  label: string;
  showPlaceholder?: boolean;
  component: <T>(props: T) => JSX.Element;
  children: ReactNode;
  className: string;
};

// Enhancement: Provide placeholder function, so that developer can decide the logic.
function IconButton(props: Props) {
  const { label, showPlaceholder = false, component: Component = Button, children, className, ...restProps } = props;

  const placeholderText = useMemo(() => {
    if (!showPlaceholder) return '';

    return label
      .split(' ')
      .map((w) => w[0])
      .join('')
      .substring(0, 2)
      .trim();
  }, [label, showPlaceholder]);

  return (
    <Component
      className={clsx(
        'relative after:absolute after:left-1/2 after:top-1/2 after:h-[45px] after:w-full after:min-w-[45px] after:-translate-x-1/2 after:-translate-y-1/2',
        className
      )}
      aria-label={label}
      {...restProps}
    >
      {children}
      {showPlaceholder ? placeholderText : null}
    </Component>
  );
}

export { IconButton };
