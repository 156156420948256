import { ReactNode, ElementType } from 'react';
import clsx from 'clsx';
import { FocusTrap } from 'libs/focus-trap';

import { TransitionFade } from 'components/Transition';

interface ModalProps {
  className?: string;
  show: boolean;
  fullScreen?: boolean;
  showBackdrop?: boolean;
  onClose?: () => void;
  TransitionComponent?: ElementType;
  FocusTrapProps?: Record<string, unknown>;
  children?: ReactNode | string;
}

interface NoTransitionProps {
  items: boolean;
  ElementProps?: Record<string, unknown>;
  children?: ReactNode | string;
}

function NoTransition(props: NoTransitionProps) {
  const { items, children, ElementProps, ...restProps } = props;

  return items ? (
    <div className="absolute left-0 top-0 z-10 h-full w-full" {...restProps}>
      {children}
    </div>
  ) : null;
}

function Modal(props: ModalProps) {
  const {
    className,
    show,
    fullScreen = false,
    showBackdrop = !fullScreen,
    onClose,
    TransitionComponent = NoTransition,
    FocusTrapProps = {},
    children,
    ...restProps
  } = props;

  const Component = TransitionComponent as ElementType;
  const { className: lockClassName, ...restLockProps } = FocusTrapProps;

  return (
    <>
      <TransitionFade
        items={showBackdrop && show}
        className="fixed left-0 top-0 h-full w-full bg-black/50"
        ElementProps={{ onClick: onClose, 'data-testid': 'modal-backdrop' }}
      />
      <Component
        data-testid="modal"
        items={show}
        className={clsx(
          'fixed text-black',
          !fullScreen && 'left-1/2 top-1/3 w-full max-w-[352px] -translate-x-1/2 -translate-y-1/3 p-6', // Modal Center
          fullScreen && 'left-0 top-0 h-full w-full rounded-none rounded-t-lg',
          className
        )}
        ElementProps={{ ...restProps }}
      >
        <FocusTrap
          className={clsx('flex flex-1 flex-col justify-center', lockClassName as string)}
          lockProps={{ ...restLockProps, 'data-testid': 'modal-focus-trap' }}
        >
          {children}
        </FocusTrap>
      </Component>
    </>
  );
}

export { Modal };
