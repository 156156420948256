import { Transition, animated } from '@react-spring/web';

// Enhancement: Add typescript support and try to re-use some pieces for each animation type
function TransitionCustom(props) {
  const { children, className, ElementProps, ...rest } = props;

  return (
    <Transition {...rest}>
      {(style, isOpen) => {
        return (
          isOpen && (
            <animated.div className={className} style={style} {...ElementProps}>
              {children}
            </animated.div>
          )
        );
      }}
    </Transition>
  );
}

function TransitionUp(props) {
  return (
    <TransitionCustom
      {...props}
      from={{ opacity: 0, transform: `translateY(100%)` }}
      enter={{ opacity: 1, transform: 'translateY(0px)' }}
      leave={{ opacity: 0, transform: `translateY(100%)` }}
    />
  );
}

function TransitionDown(props) {
  return (
    <TransitionCustom
      {...props}
      from={{ opacity: 0, transform: `translateY(-100%)` }}
      enter={{ opacity: 1, transform: 'translateY(0px)' }}
      leave={{ opacity: 0, transform: `translateY(-100%)` }}
    />
  );
}

function TransitionLeft(props) {
  return (
    <TransitionCustom
      {...props}
      from={{ opacity: 0, transform: `translateX(-100%)` }}
      enter={{ opacity: 1, transform: 'translateX(0px)' }}
      leave={{ opacity: 0, transform: `translateX(-100%)` }}
    />
  );
}

function TransitionRight(props) {
  return (
    <TransitionCustom
      {...props}
      from={{ opacity: 0, transform: `translateX(100%)` }}
      enter={{ opacity: 1, transform: 'translateX(0px)' }}
      leave={{ opacity: 0, transform: `translateX(100%)` }}
    />
  );
}

function TransitionFade(props) {
  return <TransitionCustom {...props} from={{ opacity: 0 }} enter={{ opacity: 1 }} leave={{ opacity: 0 }} />;
}

function TransitionUpDownNoFade(props) {
  return (
    <TransitionCustom {...props} from={{ transform: `translateY(100%)` }} enter={{ transform: `translateY(0%)` }} leave={{ transform: `translateY(100%)` }} />
  );
}

export { TransitionCustom, TransitionUp, TransitionDown, TransitionLeft, TransitionRight, TransitionFade, TransitionUpDownNoFade };
