import IconProps from './IconProps';

function Close(props: IconProps) {
  const { width = 17, height = 17, ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path d="M1.0625 16L16.2147 1" stroke="#555250" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.2147 16L1.0625 1" stroke="#555250" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default Close;
